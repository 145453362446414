import { ChangeDetectorRef, Component, forwardRef, Injector, Input, OnInit, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DonViHanhChinhUXService } from '@app/pages/danh-muc/don-vi-hanh-chinh/services/don-vi-hanh-chinh-ux.service';
import { CapDoDonViHanhChinh, DonViHanhChinhDDLRequest, DonViHanhChinhDto, DonViHanhChinhServiceProxy } from '@shared/service-proxies/service-proxies';
import _ from 'lodash';
import { finalize, map } from 'rxjs/operators';
import { AppUtilityService } from '../../../common/custom/utility.service';
import { BaseComboComponent } from '../../_base/base-combo-abstract';
import { Subscription } from 'rxjs';

@Component({
    selector: 'don-vi-hanh-chinh-chinh-combo',
    template: ` <div class="ora-combo">
    <nz-select
                nzShowSearch
                nzServerSearch
                [nzMaxTagCount]="maxTagCount"
                [nzMaxTagPlaceholder]="maxTagPlaceholderTmpl"
                [nzAllowClear]="nzAllowClear"
                nzPlaceHolder="{{ placeHolders }}"
                [nzMode]="nzMode"
                [nzLoading]="loading"
                [(ngModel)]="_value"
                [nzDisabled]="_isDisabled"
                (nzFocus)="(onFocus)"
                style="width:100%"
                (ngModelChange)="onChangeValue($event)"
                (nzOnSearch)="search($event)"
                [nzDropdownClassName]="'oda-select-dropdown'"
                [nzDropdownRender]="renderTemplate"
                [nzSuffixIcon]="prefixTemplateUser"
            >
                <nz-option *ngFor="let item of optionList" [nzLabel]="item.displayText" [nzValue]="item.value"> </nz-option>

                <ng-template #renderTemplate>
                    <hr *ngIf="isAdd || isSearchAdvance" />
                    <div *ngIf="isSearchAdvance">
                        <a nz-button nzType="link" (click)="searchAdvance()"
                            ><i nz-icon nzType="search" nzTheme="outline"></i> Tìm kiếm</a
                        >
                    </div>
                    <div *ngIf="isAdd">
                        <a nz-button nzType="link" (click)="openCreateOrEditModal(0)"
                            ><i nz-icon nzType="plus-circle" nzTheme="outline"></i> Thêm mới</a
                        >
                    </div>
                </ng-template>

                <ng-template #prefixTemplateUser><i nz-icon nzType="down" nzTheme="outline"></i></ng-template>
            </nz-select>
            <button nz-button nzType="text" (click)="openCreateOrEditModal(_value)" *ngIf="isUpdate && _value && !disabled">
                <i nz-icon nzType="edit"></i>
            </button>
            <ng-template #maxTagPlaceholderTmpl let-selectedList>+ {{ selectedList.length }} </ng-template>
</div>`,
    styleUrls: ['./don-vi-hanh-chinh-compo.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DonViHanhChinhComboComponent),
            multi: true,
        },
    ],
})
export class DonViHanhChinhComboComponent extends BaseComboComponent implements OnInit, ControlValueAccessor {
    @Input() placeHolders?: string = '- Chọn -';
    @Input() nzAllowClear?: boolean = true;
    maxOptionRender = 50;
    @Input() isAdd = false;
    @Input() isUpdate = false;
    @Input() isSearchAdvance = false;
    @Input() capDo = -1;
    @Input() parentCode = '';
    @Input() isActived = true;
    loading = false;
    private dataSubscription: Subscription;

    constructor(
        injector: Injector,
        private _DonViHanhChinhServiceProxy: DonViHanhChinhServiceProxy,
        private _DonViHanhChinhUXService: DonViHanhChinhUXService,
    ) {
        super(injector);
    }

    ngOnInit() {
        if (this.isMulti) {
            this.nzMode = 'multiple';
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if(this._value) {
            setTimeout(()=> {
                this.onChange(undefined);
            }, 0);
        }
        this.writeValue(null);

        let isGetDataServe = true;

        if((this.capDo != CapDoDonViHanhChinh.Tinh) && (!this.parentCode)) {
            this.optionList = [];
            isGetDataServe = false;
        }

        if(isGetDataServe) this.getDataSourceFromServer();
    }



    getDataSourceFromServer() {
        if (this.dataSubscription) {
            this.dataSubscription.unsubscribe();
            this.dataSubscription = null;
        }

        let input = Object.assign(new DonViHanhChinhDDLRequest(), {
            capDo: this.capDo,
            parentCode: this.parentCode,
            isActive: this.isActived,
        });
        this.loading = true;
        this.dataSubscription = this._DonViHanhChinhServiceProxy
            .getAllToDDL(input)
            .pipe(
                map((list) => {
                    return list.map((item) => {
                        return Object.assign(item, {
                            fts: AppUtilityService.getFullTextSearch(item.displayText),
                        });
                    });
                }),
                finalize(() => {
                    this.loading = false;
                }),
            )
            .subscribe((result) => {
                this.setListOfOption(result);
                this.addSelectedNotInRender();
            });
    }

    openCreateOrEditModal(id: number) {
        let _item = Object.assign(new DonViHanhChinhDto(), { id });

        this._DonViHanhChinhUXService.openCreateOrEditModal({
            dataItem: _item,
            callBack: (result: DonViHanhChinhDto) => {
                if (result) {
                    if (id === 0) {
                        this.optionListSource.push({
                            value: result.id,
                            displayText: result.ten,
                            fts: AppUtilityService.getFullTextSearch(result.ten),
                            data: result,
                        });
                    } else {
                        var index = this.optionListSource.findIndex((x) => x.value == id);
                        if (index !== -1) {
                            this.optionListSource[index] = Object.assign(
                                {},
                                {
                                    value: result.id,
                                    displayText: result.ten,
                                    fts: AppUtilityService.getFullTextSearch(result.ten),
                                    data: result,
                                },
                            );
                        }
                    }

                    this.setListOfOption(this.optionListSource);
                    this.writeValue(result.id);
                    this.addSelectedNotInRender();
                }
            },
        });
    }

    searchAdvance() {
        this._DonViHanhChinhUXService.openSearchListModal({
            listOfSelectedValue: this.isMulti ? [...this._value] : [this._value],
            title: 'Tìm kiếm ',
            isMulti: this.isMulti,
            filter: {
                capDo: this.capDo,
                parentCode: this.parentCode,
            },
            callBack: (result: DonViHanhChinhDto[]) => {
                if (result != null && result.length > 0) {
                    if (this.isMulti) {
                        let listOfSelectedValueFromModal = result.map((x) => x.id);
                        this.writeValue(listOfSelectedValueFromModal);
                    } else {
                        let selectedValueFromModal = result[0].id;
                        this.writeValue(selectedValueFromModal);
                        this.addSelectedNotInRender();
                    }
                }
            },
        });
    }
}
