<div class="oda-body-main" [id]="formId">
    <div nz-row>
        <div nz-col nzXs="24" nzSm="12">
            <label class="font-weight-bold">Mã chuyên khoa<span class="text-red">*</span></label>
            <nz-form-control>
                <input nz-input [(ngModel)]="dataItem.maChuyenKhoa" [disabled]="isView" #maChuyenKhoa>
                <validation-custom [sModel]="dataItem.maChuyenKhoa" [nativeElement]="maChuyenKhoa"></validation-custom>
            </nz-form-control>
        </div>
        <div nz-col nzXs="24" nzSm="12">
            <label class="font-weight-bold">Tên chuyên khoa viết tắt</label>
            <nz-form-control>
                <input nz-input [(ngModel)]="dataItem.tenChuyenKhoaVietTat" [disabled]="isView">
            </nz-form-control>
        </div>
    </div>

    <div nz-row>
        <div nz-col nzXs="24" nzSm="24">
            <label class="font-weight-bold">Tên chuyên khoa đầy đủ <span class="text-red">*</span></label>
            <nz-form-control>
                <input nz-input [(ngModel)]="dataItem.tenChuyenKhoaDayDu" [disabled]="isView" #tenDayDu>
                <validation-custom [sModel]="dataItem.tenChuyenKhoaDayDu" [nativeElement]="tenDayDu"></validation-custom>
            </nz-form-control>
        </div>
        <div nz-col class="gutter-row input-add" nzXs="24" nzMd="24">
            <label class="font-weight-normal">Loại khoa phòng</label>
            <nz-form-control>
                <enum-combo [EnumCode]="ComboBoxEnumCode.LoaiKhoaPhong" [(ngModel)]="dataItem.loaiKhoaPhong"
                            [disabled]="isView">
                </enum-combo>
            </nz-form-control>
        </div>
    </div>

</div>


<div *nzModalFooter>
    <label *ngIf="!dataItem.id" nz-checkbox [(ngModel)]="isContinue"><b>Tiếp tục thêm</b></label>
    <button *ngIf="!isView" type="button" nz-button nzType="primary" (click)="save()"><i nz-icon nzType="save"></i>Lưu</button>
    <button type="button" nz-button nzType="default" (click)="close()">Đóng</button>
</div>
