export class CodeSystemConst {
    static GIOI_TINH = 'GIOI_TINH';
    static DAN_TOC = 'DAN_TOC';
    static LV_VAN_BAN = 'LV_VAN_BAN';
}

export const ModuleType = {
    NhanSu: {
        NhanVien: 'NhanVien',
    }
};

export class MimeTypeNames {
    static ApplicationXJavascript = "application/x-javascript";

    static AudioL24 = "audio/L24";

    static ApplicationXShockwaveFlash = "application/x-shockwave-flash";

    static ApplicationOctetStream = "application/octet-stream";

    static ApplicationAtomXml = "application/atom+xml";

    static TextCss = "text/css";

    static TextCmd = "text/cmd";

    static TextCsv = "text/csv";

    static ApplicationXDeb = "application/x-deb";

    static MultipartEncrypted = "multipart/encrypted";

    static MultipartSigned = "multipart/signed";

    static MessageHttp = "message/http";

    static ModelExample = "model/example";

    static ApplicationXDvi = "application/x-dvi";

    static ApplicationXmlDtd = "application/xml-dtd";

    static ApplicationJavascript = "application/javascript";

    static ApplicationEcmascript = "application/ecmascript";

    static ApplicationEdifact = "application/EDIFACT";

    static ApplicationEdiX12 = "application/EDI-X12";

    static MessagePartial = "message/partial";

    static MessageRfc822 = "message/rfc822";

    static TextXml = "text/xml";

    static VideoXFlv = "video/x-flv";

    static ImageGif = "image/gif";

    static TextXGwtRpc = "text/x-gwt-rpc";

    static ApplicationXGzip = "application/x-gzip";

    static TextHtml = "text/html";

    static ImageVndMicrosoftIcon = "image/vnd.microsoft.icon";

    static ModelIges = "model/iges";

    static MessageImdnXml = "message/imdn+xml";

    static ApplicationJson = "application/json";

    static ApplicationJsonPatch = "application/json-patch+json";

    static TextJavascript = "text/javascript";

    static ImagePjpeg = "image/pjpeg";

    static ImageJpeg = "image/jpeg";

    static TextXJqueryTmpl = "text/x-jquery-tmpl";

    static ApplicationVndGoogleEarthKmlXml = "application/vnd.google-earth.kml+xml";

    static ApplicationXLatex = "application/x-latex";

    static VideoXMatroska = "video/x-matroska";

    static ApplicationVndOpenxmlformatsOfficedocumentSpreadsheetmlSheet = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

    static ApplicationVndMsExcel = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

    static ApplicationVndOpenxmlformatsOfficedocumentPresentationmlPresentation = "application/vnd.openxmlformats-officedocument.presentationml.presentation";

    static ApplicationVndMsPowerpoint = "application/vnd.ms-powerpoint";

    static ApplicationVndOpenxmlformatsOfficedocumentWordprocessingmlDocument = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";

    static ApplicationMsword = "application/msword";

    static MultipartAlternative = "multipart/alternative";

    static MultipartMixed = "multipart/mixed";

    static MultipartRelated = "multipart/related";

    static MultipartFormData = "multipart/form-data";

    static ApplicationVndMozillaXulXml = "application/vnd.mozilla.xul+xml";

    static AudioMpeg = "audio/mpeg";

    static AudioMp4 = "audio/mp4";

    static VideoMp4 = "video/mp4";

    static VideoMpeg = "video/mpeg";

    static ModelMesh = "model/mesh";

    static AudioBasic = "audio/basic";

    static VideoOgg = "video/ogg";

    static AudioOgg = "audio/ogg";

    static ApplicationOgg = "application/ogg";

    static ApplicationXopXml = "application/xop+xml";

    static ApplicationVndOasisOpendocumentGraphics = "application/vnd.oasis.opendocument.graphics";

    static ApplicationVndOasisOpendocumentPresentation = "application/vnd.oasis.opendocument.presentation";

    static ApplicationVndOasisOpendocumentSpreadsheet = "application/vnd.oasis.opendocument.spreadsheet";

    static ApplicationVndOasisOpendocumentText = "application/vnd.oasis.opendocument.text";

    static ApplicationXPkcs12 = "application/x-pkcs12";

    static ApplicationXPkcs7Certificates = "application/x-pkcs7-certificates";

    static ApplicationXPkcs7Mime = "application/x-pkcs7-mime";

    static ApplicationXPkcs7Certreqresp = "application/x-pkcs7-certreqresp";

    static ApplicationXPkcs7Signature = "application/x-pkcs7-signature";

    static ApplicationPdf = "application/pdf";

    static ImagePng = "image/png";

    static ApplicationPostscript = "application/postscript";

    static VideoQuicktime = "video/quicktime";

    static ApplicationXRarCompressed = "application/x-rar-compressed";

    static AudioVndRnRealaudio = "audio/vnd.rn-realaudio";

    static ApplicationRdfXml = "application/rdf+xml";

    static ApplicationRssXml = "application/rss+xml";

    static ApplicationSoapXml = "application/soap+xml";

    static ApplicationXStuffit = "application/x-stuffit";

    static ImageSvgXml = "image/svg+xml";

    static ImageTiff = "image/tiff";

    static ApplicationXTar = "application/x-tar";

    static TextPlain = "text/plain";

    static ApplicationXFontTtf = "application/x-font-ttf";

    static TextVcard = "text/vcard";

    static AudioVorbis = "audio/vorbis";

    static AudioVndWave = "audio/vnd.wave";

    static ApplicationFontWoff = "application/font-woff";

    static VideoWebm = "video/webm";

    static AudioWebm = "audio/webm";

    static AudioXMsWax = "audio/x-ms-wax";

    static AudioXMsWma = "audio/x-ms-wma";

    static VideoXMsWmv = "video/x-ms-wmv";

    static ModelVrml = "model/vrml";

    static ModelX3DXml = "model/x3d+xml";

    static ModelX3DBinary = "model/x3d+binary";

    static ModelX3DVrml = "model/x3d+vrml";

    static ApplicationXhtmlXml = "application/xhtml+xml";

    static ApplicationZip = "application/zip";
}
